import React from 'react'
import styled from 'styled-components'
import img from 'assets/innovations.png'
import { mobileCss } from 'utils/rwd'

const InnovationsSection = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={img} alt='img' />
      </ImageWrapper>
      <CompanyDescriptionWrapper>
        <Title>&bull; Jesteśmy innowacyjni &bull;</Title>
        <Header>W <Highlight>Smart School</Highlight> prowadzimy kreatywne zajęcia urozmaicone materiałami
          multimedialnymi.</Header>
        <Description>
          Nasze sale wyposażone są w rzutnik i komputery. Utrzymujemy stały kontakt z uczniami przez
          facebooka, nieustannie motywując ich do poznawania pasjonującego świata nauki również poza zajęciami.
          <Highlight>To innowacyjne podejście naprawdę działa!</Highlight>
          <br /><br />
          Dzięki indywidualnym lekcjom uczniowie otrzymują pełne skupienie na swoich celach, a nauczyciel jest
          w stanie dostosować materiał oraz styl nauczania do indywidualnych potrzeb każdego ucznia.
        </Description>
      </CompanyDescriptionWrapper>
    </Wrapper>
  )
}

export default InnovationsSection

const Wrapper = styled.div`
  padding: 70px 50px 30px;
  display: flex;
  align-items: center;
  gap: 50px;
  max-width: 1600px;
  justify-content: center;
  margin: 0 auto;

  ${mobileCss(`
    flex-direction: column-reverse;
    padding: 40px 20px;
  `)}
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffcf00;
`

const Header = styled.div`
  font-size: 22px;
  text-transform: uppercase;
`

const Description = styled.div`
  font-size: 18px;
  margin-top: 20px;
`

const Highlight = styled.span`
  color: #2dbdef;
  font-weight: bold;
`

const CompanyDescriptionWrapper = styled.div`
  flex: 1;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  max-width: 500px;
  min-width: 500px;

  ${mobileCss(`
    max-width: unset;
    min-width: unset;
    width: 100%;
  `)}
`
