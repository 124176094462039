const theme = {
  colors: {
    primary: {
      main: '#3b3a90',
      light: '#5250B8',
      dark: '#2d2c6d'
    },
    secondary: {
      main: '#e63119',
      light: '#f2988c',
      dark: '#cf2c17',
      contrastText: '#fff'
    }
  },
  dimensions: {
    topNavHeight: '80px'
  }
}

export default theme
