import React from 'react'
import styled from 'styled-components'
import logo from 'assets/app-logo-alt.png'
import { mobileCss } from 'utils/rwd'

const ContactSection = () => {
  return (
    <Wrapper id='contact'>
      <Content>
        <SectionsWrapper>
          <DetailsWrapper>
            <Logo src={logo} alt='logo' />
            <Title>
              &bull; Pozostańmy w kontakcie &bull;
            </Title>
            <AddressItem>
              <AddressItemLabel>Adres</AddressItemLabel>
              ul. Węglowa 13/27, 81-341 Gdynia
            </AddressItem>
            <AddressItem>
              <AddressItemLabel>Telefon</AddressItemLabel>
              <a href='tel:48695714050'>+48 695 714 050</a>
            </AddressItem>
            <AddressItem>
              <AddressItemLabel>Email</AddressItemLabel>
              <a href='mailto:smartschoolpl@gmail.com'>smartschoolpl@gmail.com</a>
            </AddressItem>
          </DetailsWrapper>
          <MapWrapper>
            <Map>
              <iframe
                title='map'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.3933779088684!2d18.544170999999995!3d54.526559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda72296dd890f%3A0x1706cf19cdc43146!2sW%C4%99glowa+13%2C+Gdynia!5e0!3m2!1spl!2spl!4v1441316330980'
                frameBorder='0'
                allowFullScreen=''
                width='100%'
                height='400px'
              />
            </Map>
          </MapWrapper>
        </SectionsWrapper>
      </Content>
    </Wrapper>
  )
}

export default ContactSection

const Wrapper = styled.div`
  padding: 50px;
  background: #16365b;

  ${mobileCss(`
    padding: 40px 20px;
  `)}
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1000px;
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffcf00;
`

const SectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;

  ${mobileCss(`
    flex-direction: column;
  `)}
`

const DetailsWrapper = styled.div`
  flex: 1;

  ${mobileCss(`
    flex-direction: column;
    width: 100%;
  `)}
`

const MapWrapper = styled.div`
  flex: 1;
  background: #16365b;
  ${mobileCss(`
    flex-direction: column;
    width: 100%;
  `)}
`

const Map = styled.div`
  border-radius: 4px;
  overflow: hidden;
`

const AddressItem = styled.div`
  margin: 20px 0;
  color: #fff;
  
  a {
    color: #fff;
  }
`

const AddressItemLabel = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: #ffcf00;
  text-transform: uppercase;
`

const Logo = styled.img`
  width: 200px;
  margin-bottom: 30px;
`
