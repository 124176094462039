import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Wrapper>
      <Content>
        Smart School &copy;2023
      </Content>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: #000;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
`
