import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import appLogo from 'assets/app-logo.png'
import appLogoAlt from 'assets/app-logo-alt.png'
import { mobileCss, WebContent } from 'utils/rwd'

const TopNavigation = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const isScrolled = scrollPosition > 5

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Wrapper isScrolled={isScrolled}>
      <Logo src={isScrolled ? appLogo : appLogoAlt} alt='company-logo' />
      <Links>
        <Link href='#home'>Start</Link>
        <Link href='#offer'>Oferta</Link>
        <Link href='#contact'>Kontakt</Link>
      </Links>
    </Wrapper>
  )
}

export default TopNavigation

const Wrapper = styled.div`
  height: ${({ theme }) => theme.dimensions.topNavHeight};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all .3s;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: transparent;
  z-index: 999;
  
  a {
    color: #fff;
  }
  
  ${({ isScrolled }) => isScrolled && css`
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .2);
    
    a {
      color: #444;
    }
  `}
`

const Logo = styled.img`
  width: 150px;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  color: #fff;
  font-size: 18px;
  
  ${mobileCss(`
    display: none;
  `)}
`

const Link = styled.a`
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    color: #ffcf00;
  }
`
