import React from 'react'
import ImageGallery from 'react-image-gallery'
import sala1 from 'assets/sala1.jpeg'
import sala2 from 'assets/sala2.jpeg'
import sala3 from 'assets/sala4.jpeg'
import sala4 from 'assets/sala5.jpeg'
import sala5 from 'assets/sala6.jpeg'
import styled from 'styled-components'
import { MobileContent, mobileCss, WebContent } from 'utils/rwd'

const GallerySection = () => {
  const images = [
    { original: sala1, originalHeight: '430px' },
    { original: sala2, originalHeight: '430px' },
    { original: sala3, originalHeight: '430px' },
    { original: sala4, originalHeight: '430px' },
    { original: sala5, originalHeight: '430px' }
  ]

  const imagesMobile = [
    { original: sala1, originalHeight: '330px' },
    { original: sala2, originalHeight: '330px' },
    { original: sala3, originalHeight: '330px' },
    { original: sala4, originalHeight: '330px' },
    { original: sala5, originalHeight: '330px' }
  ]

  return (
    <Wrapper>
      <Content>
        <SectionsWrapper>
          <Details>
            <Title>&bull; Dajemy Ci swobodę &bull;</Title>
            <Header>W <Highlight>Smart School</Highlight> sam wybierasz w jaki sposób będziesz się uczył.</Header>
            <Description>
              Prowadzimy zarówno zajęcia online jak i w trybie stacjonarnym. Nasi uczniowie mają swobodę wyboru miejsca
              zajęć - mogą uczyć się w siedzibie szkoły lub poprosić o przyjazd nauczyciela i odbywać lekcje w swoim własnym
              mieszkaniu.
            </Description>
          </Details>
          <Gallery>
            <MobileContent>
              <ImageGallery
                items={imagesMobile}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
              />
            </MobileContent>
            <WebContent>
              <ImageGallery
                items={images}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
              />
            </WebContent>
          </Gallery>
        </SectionsWrapper>
      </Content>
    </Wrapper>
  )
}

export default GallerySection

const Wrapper = styled.div`
  padding: 50px;

  ${mobileCss(`
    padding: 40px 20px;
  `)}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  gap: 30px;
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffcf00;
`

const Header = styled.div`
  font-size: 22px;
  text-transform: uppercase;
`

const Description = styled.div`
  font-size: 18px;
  margin-top: 20px;
`

const Highlight = styled.span`
  color: #2dbdef;
  font-weight: bold;
`

const Details = styled.div`
  flex: 1;
`

const SectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  
  ${mobileCss(`
    flex-direction: column;
  `)}
`

const Gallery = styled.div`
  width: 630px;

  ${mobileCss(`
    width: 100%;
  `)}
`
