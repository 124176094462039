import React from 'react'
import styled from 'styled-components'
import gb from 'assets/gb.png'
import de from 'assets/de.png'
import fr from 'assets/fr.png'
import ua from 'assets/ua.png'
import it from 'assets/it.png'
import es from 'assets/es.png'
import ru from 'assets/ru.png'
import { mobileCss } from 'utils/rwd'

const LanguagesSection = () => {
  return (
    <Wrapper>
      <Title>&bull; Nie tylko język angielski &bull;</Title>
      <Description>
        Szkoła oferuje <Highlight>szeroki zakres zajęć</Highlight>, w tym zajęcia z gramatyki, słownictwa, rozmów,
        słuchania i pisania. Dodatkowo, nauczyciele mają doświadczenie <Highlight>w nauczaniu różnych
        języków</Highlight>, takich jak angielski, hiszpański, francuski, niemiecki, włoski i wiele innych.
      </Description>
      <Flags>
        <img src={gb} alt='gb' />
        <img src={de} alt='de' />
        <img src={fr} alt='fr' />
        <img src={ua} alt='ua' />
        <img src={it} alt='it' />
        <img src={es} alt='es' />
        <img src={it} alt='it' />
        <img src={ru} alt='ru' />
      </Flags>
    </Wrapper>
  )
}

export default LanguagesSection

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px;

  ${mobileCss(`
    padding: 40px 20px;
  `)}
`

const Flags = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  img {
    width: 64px;
  }
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffcf00;
  text-align: center;
  margin-bottom: 10px;

  ${mobileCss(`
    text-align: left;
  `)}
`

const Description = styled.div`
  text-align: center;
  margin-bottom: 30px;
  ${mobileCss(`
    text-align: left;
  `)}
`

const Highlight = styled.span`
  color: #2dbdef;
  font-weight: bold;
`
