import React from 'react'
import styled from 'styled-components'
import bg from 'assets/bg.jpeg'
import gb from 'assets/gb.png'
import de from 'assets/de.png'
import fr from 'assets/fr.png'
import ua from 'assets/ua.png'
import it from 'assets/it.png'
import es from 'assets/es.png'
import ru from 'assets/ru.png'
import { mobileCss } from 'utils/rwd'

const WelcomeSection = () => {
  return (
    <Wrapper id='home'>
      <ContentWrapper>
        <LeftSection>
          <Flags>
            <img src={gb} alt='gb' />
            <img src={de} alt='de' />
            <img src={fr} alt='fr' />
            <img src={ua} alt='ua' />
            <img src={it} alt='it' />
            <img src={es} alt='es' />
            <img src={it} alt='it' />
            <img src={ru} alt='ru' />
          </Flags>
          <Header>&bull; Centrum nauki języków obcych &bull;</Header>
          <Description>Tu&nbsp;<span>teoria</span>&nbsp;spotyka się&nbsp;z&nbsp;<span>praktyką</span></Description>
        </LeftSection>
        <div />
      </ContentWrapper>
    </Wrapper>
  )
}

export default WelcomeSection

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 200px 60px 40px;
  background: transparent url('${bg}') no-repeat center/cover;

  ${mobileCss(`
    background-position: 60% 0;
    padding: 120px 20px 40px;
    height: 400px;
  `)}
`

const ContentWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
  
  & > div {
    flex: 1;
  }
  
  ${mobileCss(`
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    background: rgba(50, 50, 50, 0.7)
  `)}
`

const LeftSection = styled.div`
  color: #fff;
  flex: 1;
  text-transform: uppercase;
`

const Header = styled.div`
  font-size: 16px;
  color: #f2f2f2;
  margin-bottom: 10px;

  ${mobileCss(`
    text-align: center;
  `)}
`

const Description = styled.div`
  font-size: 58px;
  font-weight: 300;
  
  span {
    font-weight: 700;
    color: #ffcf00;
  }

  ${mobileCss(`
    font-size: 30px;
    text-align: center;
  `)}
`

const Flags = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  
  img {
    width: 30px;

    ${mobileCss(`
      width: 20px;
    `)}
  }

  ${mobileCss(`
    justify-content: center;
  `)}
`
