import React from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/rwd'

const PassionSection = () => {
  return (
    <Wrapper>
      <Content>
        <Title>
          <Highlight>Dla nas to więcej</Highlight>, niż edukacja
        </Title>
        <Description>
          Jesteśmy młodym zespołem nauczycieli z pasją. Dobrze wiemy, jak ważne są decyzje podejmowane przez młodzież w
          tym trudnym wieku. Oprócz wsparcia dydaktycznego, pragniemy motywować uczniów do pracy i wskazywać jak
          efektywnie się uczyć, pomagać w wyborze właściwej ścieżki rozwoju.
          <br /><br />
          Zespół Smart School tworzą dydaktycy z powołania, których łączy pasja przekazywania wiedzy i kreatywne podejście
          do metod nauczania. <Highlight>Z nami zajęcia nigdy nie są nudne!</Highlight>
        </Description>
      </Content>
    </Wrapper>
  )
}

export default PassionSection

const Wrapper = styled.div`
  padding: 50px;
  background-image: linear-gradient(to right top, #16365b, #205580, #2675a5, #2a98cb, #2dbdef);

  ${mobileCss(`
    padding: 40px 20px;
  `)}
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 1000px;
`

const Title = styled.div`
  font-size: 38px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
`

const Description = styled.div`
  font-size: 18px;
  margin-top: 20px;
  color: #fff;
  text-align: center;
`

const Highlight = styled.span`
  color: #ffcf00;
  font-weight: bold;
`
