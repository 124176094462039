import React from 'react'
import styled from 'styled-components'
import img from 'assets/services.png'
import { mobileCss } from 'utils/rwd'

const ServicesSection = () => {
  return (
    <Wrapper id='offer'>
      <CompanyDescriptionWrapper>
        <Title>&bull; Poznajmy się bliżej &bull;</Title>
        <Header>Centrum edukacji <Highlight>Smart School</Highlight> powstało, aby obalić mit o konieczności nudnego zakuwania materiału. U nas <Highlight>teoria
          spotyka się z praktyką!</Highlight></Header>
        <Description>
          Nasza szkoła oferuje indywidualne zajęcia na wszystkich poziomach zaawansowania, co oznacza, że może pomóc zarówno
          początkującym jak i zaawansowanym studentom w osiągnięciu ich celów językowych. Lekcje odbywają się w trybie
          zdalnym lub stacjonarnym, zależnie od preferencji ucznia.
        </Description>
      </CompanyDescriptionWrapper>
      <ImageWrapper>
        <Image src={img} alt='img' />
      </ImageWrapper>
    </Wrapper>
  )
}

export default ServicesSection

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  gap: 50px;
  max-width: 1600px;
  justify-content: center;
  margin: 0 auto;

  ${mobileCss(`
    flex-direction: column;
    padding: 40px 20px;
  `)}
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffcf00;
`

const Header = styled.div`
  font-size: 22px;
  text-transform: uppercase;
`

const Description = styled.div`
  font-size: 18px;
  margin-top: 20px;
`

const Highlight = styled.span`
  color: #2dbdef;
  font-weight: bold;
`

const CompanyDescriptionWrapper = styled.div`
  flex: 1;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  max-width: 400px;
  min-width: 400px;

  ${mobileCss(`
    max-width: unset;
    min-width: unset;
    width: 100%;
  `)}
`


